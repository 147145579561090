import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';
import { Flex, Title } from '@/common/components';

import style from './index.module.scss';

interface AgErrorProps extends IntrinsicProps {
  errorCode: string,
  errorMessage: string
}
export default createComponent<AgErrorProps>('AgError', 
  { style }, 
  function AgError ({ className }, props) {
    return (
      <div className={className}>
        <Flex center justifyCenter>
          <Flex directionColumn alignCenter>
            <Title h1 size1>{ props.errorCode }</Title>
            <Title h2 size2>{ props.errorMessage }</Title>
          </Flex>
        </Flex>
        <Flex className="AgError__Bunny__Game">
          <object 
            data={'https://cdn2.addictinggames.com/addictinggames-content/ag-assets/content-items/qa/404-test/index.html'} 
            style={{ width: '100%', height: '100%' }}
          ></object>
        </Flex>
      </div>
    );
  });