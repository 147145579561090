import { Container } from '@/common/components';
import { Meta } from '@/common/components/head';
import { createPage } from '@/common/util/templateHelpers';
import AgError from '@/components/AgError';
import NoPaddingLayout from '@/layouts/NoPaddingLayout';

export default createPage('Custom404Page', 
  { }, 
  function Custom404Page ({ className }) {
    return (
      <NoPaddingLayout className={className}>
        <Meta 
          titleTag='404 | Addicting Games'
          title='404' 
        />
        <Container>
          <AgError 
            errorCode="404"
            errorMessage="PAGE NOT FOUND"
          />
        </Container>
      </NoPaddingLayout>
    );
  });
