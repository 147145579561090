import { createComponent, IntrinsicProps } from '@/common/util/templateHelpers';

import style from './index.module.scss';

interface BlockContainerProps extends IntrinsicProps {
  altBackground?: boolean
  doubleSpacing?: boolean
}

const blockContainerClassStates = [
  'altBackground',
  'doubleSpacing'
];

export default createComponent<BlockContainerProps>('BlockContainer', 
  { classStates: blockContainerClassStates, style }, 
  function BlockContainer ({ className }, props) {
    return (
      <div className={className}>
        {props.children}
      </div>
    );
  });
