import { createComponent, If } from '@/common/util/templateHelpers';
import { AdContainer, AdContainerSize, App, Block, Flex, Layout } from '@/common/components';

import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';

import style from './index.module.scss';
import BlockContainer from '@/components/BlockContainer';
import CategoryCarousel from '@/components/CategoryCarousel';
import RecentGamesBlock from '@/components/RecentGamesBlock';
import Yolla, { YollaType, YollaSubType } from '@/components/Yolla';
import { useAuthStore } from '@/stores/auth';

export default createComponent('NoPaddingLayout', { style }, function NoPaddingLayout ({ className }, props) {
  const [ authState ] = useAuthStore();

  return (
    <Layout className={className}>
      <App.Header>
        <AppHeader />
      </App.Header>

      <App.Main>
        {/* Top ad (mobile only) */}
        {
          If(authState.ready && !authState.user?.hasGamePass, () => (
            <BlockContainer className='--hideDesktop'>
              <Block>
                <Flex>
                  <AdContainer size={AdContainerSize.MOBILE_LEADERBOARD}>
                    <Yolla type={YollaType.MOBILE_LEADERBOARD} subType={YollaSubType.MOBILE_LEADERBOARD} num={4} />
                  </AdContainer>
                </Flex>
              </Block>
            </BlockContainer>
          )).EndIf()
        }
        {props.children}
      </App.Main>

      <App.Footer>
        <AppFooter />
      </App.Footer>
    </Layout>
  );
});
